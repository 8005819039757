<style>
    .wrapper {
        text-align: center;
    }
    .box {
        height: 90%;
        width: auto;

        aspect-ratio: 1/1;

        margin: 5%;

        border: 3px solid;
        box-sizing: border-box;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        display: block;
        height: 95%;
        width: 95%;
    }

    p {
        color: gray;
    }
</style>
<div class="wrapper">
    <div class="box" style="border-color: red">
        <img src="img/logo.png" alt="">
    </div>
    <p>&nbsp</p>
</div>